import React, { useEffect, useState } from "react";
import {  Container, Table } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom/cjs/react-router-dom";

export const ModelDetail = () => {

    const { modelId, modelVersionId } = useParams();

    const [modelDetail, setModelDetail] = useState([]);
    const [loading, setLoading] = useState(true);

    const [modelComparisonMetricsFields, setModelComparisonMetricsFields] = useState([]);

    const [userRefresh, setUserRefresh] = useState(new Date());

    const {
        getAccessTokenSilently,
    } = useAuth0();


    const getModelComparisonFields = (metrics) => {
        if (metrics === null || metrics.length === 0) {
            return [];
        }

        return Object.keys(metrics[0]).filter((f) => f !== "Model").sort();
    }

    const callApi = async (url, opt) => {

        setLoading(true);
        const token = await getAccessTokenSilently();

        const bearer_token = `Bearer ${token}`;

        const response = await fetch(url, {
            ...opt,
            headers: {
                "Content-Type": "application/json",
                "Authorization": bearer_token,
            },
        });

        const responseData = await response.json();

        console.log(responseData);

        setModelDetail(responseData);
        setModelComparisonMetricsFields(getModelComparisonFields(responseData["training_results"]["model_comparison_metrics"]));
        setLoading(false);

    };

    useEffect(() => {

        callApi("https://c2w06calch.execute-api.us-east-1.amazonaws.com/model/" + modelId + '/' + modelVersionId, { method: "GET" });


    }, [userRefresh, modelId, modelVersionId]);


    return (

        <Container fluid>

            <>
                {loading && <p>Loading. Please wait...</p>}
                {!loading &&

                <>
                    <h1>Model Training Inputs</h1>

                    <Table>

                        <tr>
                            <td>Model Name</td><td>{modelDetail["training_parameters"]["model_name"]}</td>
                        </tr>
                        <tr>
                            <td>Target Variable</td><td>{modelDetail["training_parameters"]["target_variable"]}</td>
                        </tr>
                        <tr>
                            <td>Training Data Format</td><td>{modelDetail["training_parameters"]["training_data_format"]}</td>
                        </tr>

                        <tr>
                            <td>Notes</td><td>{modelDetail["training_parameters"]["notes"]}</td>
                        </tr>

                    </Table>

                    <h1>Training Results</h1>

                    <Table>

                        <tr>
                            <td>Runtime</td><td>{modelDetail["training_results"]["runtime"]}</td>
                        </tr>
                        <tr>
                            <td>Runtime Version</td><td>{modelDetail["training_results"]["runtime_version"]}</td>
                        </tr>
                        <tr>
                            <td>Framework</td><td>{modelDetail["training_results"]["framework_name"]}</td>
                        </tr>
                        <tr>
                            <td>Framework Version</td><td>{modelDetail["training_results"]["framework_version"]}</td>
                        </tr>

                    </Table>

                    <h1>Model Training Features</h1>

                    {!modelDetail["training_results"]["training_input_features"] && <em>Information not available for this model</em>}
                    {modelDetail["training_results"]["training_input_features"] &&
                        <Table>

                            <tr>
                                <th>Field</th><th>Data Type</th>
                            </tr>


                            {modelDetail["training_results"]["training_input_features"].map(f =>
                                <tr id={f}>
                                    <td>{f}</td><td>{modelDetail["training_results"]["training_input_feature_types"][f]}</td>
                                </tr>

                            )
                            }

                        </Table>
                    }

                    <h1>Model Comparison Metrics</h1>

                    {!modelDetail["training_results"]["model_comparison_metrics"] && <em>Information not available for this model</em>}
                    {modelDetail["training_results"]["model_comparison_metrics"] &&

                        <Table>

                            <tr>
                                <th>Model</th>
                                    { modelComparisonMetricsFields.map(stat =>
                                        <th>{ stat }</th>
                                    )}
                            </tr>


                            {modelDetail["training_results"]["model_comparison_metrics"] && modelDetail["training_results"]["model_comparison_metrics"].map(comparison =>
                                <tr id={comparison.Model}>
                                    <td>{comparison.Model}</td>
                                    { modelComparisonMetricsFields.map(stat =>
                                        <td>{ comparison[stat] }</td>
                                    )}
                                </tr>

                            )}
                        </Table>
                    }

                </>

                }
            </>


        </Container >
    );

};

export default ModelDetail;
