import React, { useEffect, useState } from "react";
import { Button, Table, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from "reactstrap";

import { useWizard } from 'react-use-wizard';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
//import { getConfig } from "../config";
//import Loading from "../components/Loading";


const ReviewSettings = (props) => {
    const { handleStep, previousStep, nextStep } = useWizard();

    const [sampleData, setSampleData] = useState({ headers: [], rows: [] });

    const { modelIdentification, trainingDataSource, targetVariable, modelType } = props;


    const {
        getAccessTokenSilently,
        loginWithPopup,
        getAccessTokenWithPopup,
    } = useAuth0();


    const callApi = async (url, opt) => {
        try {
            const token = await getAccessTokenSilently();

            const bearer_token = `Bearer ${token}`;

            console.log(bearer_token);

            const response = await fetch(url, {
                ...opt,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": bearer_token,
                },
            });

            const responseData = await response.json();

            await setSampleData(
                responseData
            );
        } catch (error) {

            console.log(error.error);
        }
    };


    useEffect(() => {
        /*
                callApi("https://c2w06calch.execute-api.us-east-1.amazonaws.com/data/sample", {
                    method: "POST",
                    body: JSON.stringify({
                        "source_url": data_url,
                        "records": 5
                    })
                });*/
    }, []);

    // Attach an optional handler
    handleStep(() => {
        //alert('Going to step 2');
    });

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        <>

            <p>Below are the settings which we will use to train your model.
                Please review for accuracy before submitting.
            </p>
            <p><b>Model Name:</b> {modelIdentification.model_name}</p>

            <p><b>Data Source:</b> {trainingDataSource.url}</p>

            <p><b>Target Variable:</b> {targetVariable}</p>

            <p><b>Model Type:</b> {modelType}</p>

            <p><b>Notes:</b></p>
            <p>{modelIdentification.notes}</p>



        </>
    );
};

export default ReviewSettings;
