import React from "react";

import dev_logo from "../assets/mia_dev.png";
import beta_logo from "../assets/mia_beta.png";
import prod_logo from "../assets/mia_prod.png";

const getProdLevel = () => {

  var prodLevel = "dev";

  if (window.location.hostname == "beta.mia-ml.com") {
    prodLevel = "beta";
  }

  if (window.location.hostname == "mia-ml.com" || window.location.hostname == "www.mia-ml.com") {
    prodLevel = "prod";
  }

  return prodLevel;

}

const title = () => {
  const titles = {
    "dev": "Development",
    "beta": "Beta",
    "prod": ""
  };

  return titles[getProdLevel()]
}

const heroLogo = () => {
  const titles = {
    "dev": dev_logo,
    "beta": beta_logo,
    "prod": prod_logo
  };

  return titles[getProdLevel()]
}


const Hero = () => (
  <div className="text-center hero my-5">
    <img className="mb-3 app-logo" src={heroLogo()} alt="Dev logo" width="120" />
    <h1 className="mb-4">Mia {title()}</h1>

    <p className="lead">
      This is a <b>Technology Preview</b> for our new system. Some
      things will work. Some things will work a little. Some things
      will not work at all. Have fun, and have patience.
    </p>
  </div>
);

export default Hero;
