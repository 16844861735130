import React, { useEffect, useState } from "react";
import { Button, Table, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Container, Row, Col } from "reactstrap";

import { useWizard } from 'react-use-wizard';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import DataTable from "../data/DataTable";
import TargetDataSelector from "../data/TargetDataSelector";



const ViewSampleData = (props) => {
    const { handleStep, previousStep, nextStep } = useWizard();

    const [sampleData, setSampleData] = useState({ headers: [], rows: [] });

    const modelTypeList = ["Regression", "Classification"];

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const { data_source, targetVariable, setTargetVariable, modelType, setModelType } = props;

    const [loading, setLoading] = useState(true);


    const {
        getAccessTokenSilently,
        loginWithPopup,
        getAccessTokenWithPopup,
    } = useAuth0();


    const callApi = async (url, opt) => {
        try {
            const token = await getAccessTokenSilently();

            const bearer_token = `Bearer ${token}`;

            console.log(bearer_token);

            const response = await fetch(url, {
                ...opt,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": bearer_token,
                },
            });

            const responseData = await response.json();

            await setSampleData(
                responseData
            );

            await setLoading(false);
        } catch (error) {
            console.log(error.error);
        }
    };


    useEffect(() => {

        callApi("https://c2w06calch.execute-api.us-east-1.amazonaws.com/data/sample", {
            method: "POST",
            body: JSON.stringify({
                "source": data_source,
                "records": 5
            })
        });
    }, []);

    // Attach an optional handler
    handleStep(() => {
        //alert('Going to step 2');
    });

    return (
        <>

            <Row>
                <Col>Which column from the training data below do you want the model to predict? This is the <em>target variable</em>.</Col>
                <Col xs={2}>
                    <TargetDataSelector targetVariable={targetVariable} setTargetVariable={setTargetVariable} featureList={sampleData.headers} />
                </Col>

            </Row>

            <Row style={{ marginTop: "3em" }}><Col><h3>Select Model Type</h3></Col></Row>

            <Row>
                <Col>What problem is your model supposed to solve? Is it <em>classification</em> or <em>regression</em>.</Col>
                <Col xs={2}>
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle color="primary" caret>{modelType ? `Model Type: ${modelType}` : "Select Model Type"}</DropdownToggle>
                        <DropdownMenu onSelect={(e) => setModelType(e.target.value)}>
                            {modelTypeList.map((h) => <DropdownItem key={h} onClick={() => setModelType(h)}>{h}</DropdownItem>)}
                        </DropdownMenu>
                    </Dropdown>
                </Col>

            </Row>

            <Row style={{ marginTop: "3em" }}><Col><h3>Training Data Preview</h3></Col></Row>

            <Row><Col>
                {loading && <h2 style={{ marginTop: "3em", marginBottom: "3em" }}>Loading...</h2>}
                {!loading && <DataTable headers={sampleData.headers} rows={sampleData.rows} ></DataTable>}
            </Col></Row>

        </>
    );
};

export default ViewSampleData;
