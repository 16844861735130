import React, { Fragment } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Container, Row, Col } from 'reactstrap';

import { useWizard } from 'react-use-wizard';
import { Button, Form, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';

import DataSourceSelector from "../data/DataSourceSelector";

const GetDataSource = ({ inferenceData, setInferenceData }) => {
    const { handleStep } = useWizard();

    // Attach an optional handler
    handleStep(() => {
        //alert("Done getting data. About to view.");

    });

    return (

        <Form style={{ "marginTop": "4em", "marginBottom": "4em" }} >
        <FormGroup>
            <Row>
                <Col>
                    <Label for="trainingDataURL">
                        Inference Data Source
                    </Label>
                </Col>
            </Row>
            <DataSourceSelector dataSource={inferenceData} setDataSource={setInferenceData} />
        </FormGroup>
        </Form>


    );
};

export default GetDataSource;
