import React, { useState } from "react";


import {  Row, Col } from 'reactstrap';

import { Input, FormFeedback, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, } from 'reactstrap';

const DataSourceSelector = ({ dataSource, setDataSource }) => {

    const [acquisitionType, setAcquisitionType] = useState("Url")


    const updateDataSource = (newDataSource) => {
        setDataSource({
            ...newDataSource,
        });
    };

    const updateUrl = (url) => {
        updateDataSource({
            ...dataSource,
            acquisition: { Url: { source_url: url } }
        })
    }

    const updateS3Bucket = (bucket, prevSource) => {
        const source = prevSource || { S3: { bucket: "", key: "" } };
        const s3 = source.S3 || { bucket: "", key: "" };

        updateDataSource({
            ...dataSource,
            acquisition: { S3: { bucket: bucket, key: s3.key } }
        })
    }

    const updateS3Key = (key, prevSource) => {
        const source = prevSource || { S3: { bucket: "", key: "" } };
        const s3 = source.S3 || { bucket: "", key: "" };

        updateDataSource({
            ...dataSource,
            acquisition: { S3: { bucket: s3.bucket, key: key } }
        })
    }

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        <Row>
            <Col xs={2}>
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle color="primary" caret>{acquisitionType ? `Source Type: ${acquisitionType}` : "Select Source Type"}</DropdownToggle>
                    <DropdownMenu onSelect={(e) => setAcquisitionType(e.target.value)}>
                        <DropdownItem onClick={() => setAcquisitionType("Url")}>URL</DropdownItem>
                        <DropdownItem onClick={() => setAcquisitionType("S3")}>S3</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </Col>
            <Col>

                {acquisitionType == "S3" &&
                    <>
                        <Input placeholder="S3 Bucket" type="text" onChange={(e) => updateS3Bucket(e.target.value, dataSource.acquisition)} />

                        <Input placeholder="S3 Key" type="text" onChange={(e) => updateS3Key(e.target.value, dataSource.acquisition)} />

                    </>
                }

                {acquisitionType == "Url" &&
                    <>
                        <Input type="url" onChange={(e) => updateUrl(e.target.value)} />
                        <FormFeedback>
                            Please enter a valid URL
                        </FormFeedback>
                    </>
                }
            </Col>
        </Row>
    );

}

export default DataSourceSelector;