import React, { useState } from 'react'
import { Wizard } from 'react-use-wizard';

import { Container, Row, Col, Button } from 'reactstrap';

// import GetDataSource from '../../../components/training/GetDataSource';
// import ReviewSettings from '../../../components/training/ReviewSettings';
// import ViewSampleData from '../../../components/training/ViewSampleData';
// import TrainingSubmitted from '../../../components/training/TrainingSubmitted';

import GetDataSource from '../../../components/training/GetDataSource';
import ModelIdentification from '../../../components/training/ModelIdentification';
import ReviewSettings from '../../../components/training/ReviewSettings';
import TargetDataSelection from '../../../components/training/TargetDataSelection';
import TrainingSubmitted from '../../../components/training/TrainingSubmitted';
import WizardPanel from '../WizardPanel';


const TrainModelWizard = () => {

    const queryParameters = new URLSearchParams(window.location.search)
    const debug = queryParameters.get("debug") == 1 ? true : false;

    const [trainingData, setTrainingData] = useState({
        acquisition: {},
        format: "Csv",
    });

    const [modelIdentification, setModelIdentification] = useState({
        model_name: undefined,
        notes: undefined
    });

    const [targetVariable, setTargetVariable] = useState(undefined);

    const [modelType, setModelType] = useState(undefined);

    const [trainingResults, setTrainingResults] = useState(undefined);



    return (
        <Container fluid>

            <Row>
                <Col>
                    <Wizard>
                        <WizardPanel title="Identify Your New Model"><ModelIdentification modelIdentification={modelIdentification} setModelIdentification={(modelIdData) => setModelIdentification(modelIdData)} /></WizardPanel>
                        <WizardPanel title="Set Up Training Data Source"><GetDataSource trainingData={trainingData} setTrainingData={(data) => setTrainingData(data)} /></WizardPanel>
                        <WizardPanel title="Select Target Variable"><TargetDataSelection data_source={trainingData.acquisition} targetVariable={targetVariable} setTargetVariable={(variable) => setTargetVariable(variable)} modelType={modelType} setModelType={(variable) => setModelType(variable)} /></WizardPanel>
                        <WizardPanel title="Confirm Settings and Submit"><ReviewSettings modelIdentification={modelIdentification} trainingDataSource={trainingData} targetVariable={targetVariable} modelType={modelType} /></WizardPanel>
                        <WizardPanel title="Training Results"><TrainingSubmitted modelIdentification={modelIdentification} trainingResults={trainingResults} setTrainingResults={setTrainingResults} trainingDataSource={trainingData} targetVariable={targetVariable} modelType={modelType} /></WizardPanel>
                    </Wizard>
                </Col>
            </Row>

            {debug && <Row>
                <Col>
                    {JSON.stringify(trainingData)}
                </Col>
            </Row>}


        </Container>

    )
}

export default TrainModelWizard;