import React, { useState, useEffect } from "react";

import { useWizard } from 'react-use-wizard';
import { Button } from 'reactstrap';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";


import ModelInformation from "../model/ModelInformation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faSync } from "@fortawesome/free-solid-svg-icons";

const TrainingSubmitted = ({ trainingResults, setTrainingResults, trainingDataSource, targetVariable, modelIdentification, modelType }) => {
    const { handleStep, previousStep, nextStep } = useWizard();

    //const { trainingResults, setTrainingResults } = useState();

    const [loading, setLoading] = useState(true);


    const {
        getAccessTokenSilently,
        loginWithPopup,
        getAccessTokenWithPopup,
    } = useAuth0();


    const callApi = async (url, opt) => {
        try {
            const token = await getAccessTokenSilently();

            const bearer_token = `Bearer ${token}`;

            console.log(bearer_token);

            const response = await fetch(url, {
                ...opt,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": bearer_token,
                },
            });

            const responseData = await response.json();

            console.log(responseData);

            await setTrainingResults({
                ...responseData
            });

            await setLoading(false);
        } catch (error) {

            console.log(error.error);
        }
    };


    useEffect(() => {

        callApi("https://c2w06calch.execute-api.us-east-1.amazonaws.com/model/train", {
            method: "POST",
            body: JSON.stringify({
                "model_name": modelIdentification.model_name,
                "notes": modelIdentification.notes,
                "target_variable": targetVariable,
                "model_type": modelType,
                "acquisition": trainingDataSource.acquisition,
            })
        });

    }, []);

    return (
        <>
            {loading &&
                <div style={{ marginTop: "3em", marginBottom: "3em", textAlign: "center" }}>
                    <h2 style={{ marginBottom: "3em" }}>
                        Submitting Model Training
                    </h2>

                    <h1 style={{ marginTop: "1em", marginBottom: "1em" }}><FontAwesomeIcon style={{ fontSize: "5em" }} className="fa-spin" icon={faSync} /></h1>

                    <p>Your model training request is being submitted. Please do not navigate away just yet.</p>
                </div>
            }

            {!loading &&
                <div style={{ marginTop: "3em", marginBottom: "3em", textAlign: "center" }}>
                    <h2>Model Training Submitted!</h2>

                    <p>You can head back to the models list to monitor the training status.</p>

                    <p><a href="/model">Back to models list</a></p>
                </div>
            }

        </>
    );
};

export default TrainingSubmitted;
