import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table, Tooltip } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { faArrowAltCircleRight, faLightbulb, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const PredictionJobList = () => {
    const { user } = useAuth0();


    const [jobList, setModelList] = useState([]);
    const [loading, setLoading] = useState(true);

    const [userRefresh, setUserRefresh] = useState(new Date());

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const statusMap = {
        "TRAINING": "Training in Progress",
        "TRAINED": "Training Complete"
    }

    const friendlyStatus = (status) => {
        return statusMap[status];
    }


    const {
        getAccessTokenSilently,
        loginWithPopup,
        getAccessTokenWithPopup,
    } = useAuth0();


    const callApi = async (url, opt) => {
        //try {

        setLoading(true);
        const token = await getAccessTokenSilently();


        const bearer_token = `Bearer ${token}`;

        console.log(bearer_token);

        const response = await fetch(url, {
            ...opt,
            headers: {
                "Content-Type": "application/json",
                "Authorization": bearer_token,
            },
        });

        const responseData = await response.json();

        console.log(responseData);

        await setModelList(responseData);
        await setLoading(false);
        //} catch (error) {
        //    console.log(error.error);
        //}
    };


    useEffect(() => {

        callApi("https://c2w06calch.execute-api.us-east-1.amazonaws.com/predictions", { method: "GET" });


    }, [userRefresh]);


    return (

        <Container fluid>
            <Row style={{ paddingBottom: "1em" }}>
                <Col style={{ textAlign: "right" }}>
                    <Button outline onClick={() => setUserRefresh(new Date())}><FontAwesomeIcon icon={faSync} /></Button>
                </Col>
            </Row>

            <Row>

                <Table>

                    <thead>
                        <tr>
                            <th>Creation Time</th>
                            <th>Job Status</th>
                            <th>File</th>
                            <th>Messages</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {loading && <tr><td colSpan="2" style={{ textAlign: "center" }}>
                            <h1><FontAwesomeIcon style={{ fontSize: "3em" }} className="fa-spin" icon={faSync} /></h1>
                        </td></tr>}

                        {(!loading && jobList) &&
                            jobList.map(job => <tr key={job.model_id}>
                                <td>{Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(job.created_at * 1000)}</td>
                                <td>{job.status}</td>
                                <td>{job.download_link && <a href={job.download_link}>Download</a>}</td>
                                <td>{job.errorMessage && <pre>{job.errorMessage}</pre>}</td>
                                <td className="predictionJobOperations">
                                    {job.status === "COMPLETE" &&
                                        <span onMouseEnter={() => setTooltipOpen(job.model_id)} onMouseLeave={() => setTooltipOpen(false)}>
                                            <Link id={"predictionJob-" + job.model_id} to={'/model/predict/' + job.model_id + '/' + job.model_version_id + '/batch'}>
                                                <FontAwesomeIcon style={{ color: "#d4d120" }} icon={faLightbulb} alt="Run Predictions" />
                                            </Link>
                                            <Tooltip placement="top" isOpen={tooltipOpen == job.model_id} target={"predictionJob-" + job.model_id} >
                                                Run Predictions
                                            </Tooltip>
                                        </span>
                                    }
                                </td>
                            </tr>
                            )
                        }

                    </tbody>
                </Table >
            </Row>
            <Row>
                <Col>
                    <small>Last updated: {userRefresh.toString()}</small>
                </Col>

            </Row>
        </Container >
    );

};

export default PredictionJobList;