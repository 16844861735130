import React, { useEffect, useState } from "react";
import { Button, Table, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from "reactstrap";

import { useWizard } from 'react-use-wizard';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
//import { getConfig } from "../config";
//import Loading from "../components/Loading";


const ReviewSettings = (props) => {
    const { modelIdentification, inferenceData } = props;

    const {
        getAccessTokenSilently,
    } = useAuth0();

    return (
        <>

            <p>Below are the settings which we will use to train your model.
                Please review for accuracy before submitting.
            </p>
            <p><b>Model ID:</b> {modelIdentification.model_id}</p>

            <p><b>Data Source:</b> {inferenceData.url}</p>


        </>
    );
};

export default ReviewSettings;
