import React from 'react';

import { useWizard } from 'react-use-wizard';
import { Container, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStepBackward, faStepForward } from '@fortawesome/fontawesome-free-solid';


const WizardPanel = (props) => {

    const { previousStep, nextStep } = useWizard();

    return (
        <Container fluid>
            <Row><Col><h3>{props.title}</h3></Col></Row>

            <Row><Col>
                {props.children}
            </Col></Row>

            <Row>
                <Col xs="2" style={{ textAlign: "left" }}>
                    <Button color="primary" onClick={() => previousStep()}><FontAwesomeIcon icon={faStepBackward} />&nbsp;Previous</Button>
                </Col>
                <Col></Col>
                <Col xs="2" style={{ textAlign: "right" }}>
                    <Button color="primary" onClick={() => nextStep()}>Next&nbsp;<FontAwesomeIcon icon={faStepForward} /></Button>
                </Col>
            </Row>

        </Container>
    );

};

export default WizardPanel;