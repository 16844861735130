import React, { Fragment } from "react";

import TrainModelWizard from "./wizards/TrainModelWizard/TrainModelWizard";

const TrainModel = () => (
  <Fragment>
    <TrainModelWizard />
  </Fragment>
);

export default TrainModel;
