import React from 'react';

import { Table } from 'reactstrap';

const DataTable = (props) => {

    return (
        <Table>
            <thead>
                <tr>
                    {
                        props.headers.map((h) => { return (<th>{h}</th>) })
                    }
                </tr>
            </thead>

            <tbody>
                {
                    props.rows.map((row) =>
                        <tr>
                            {
                                row.map((col) => <td>{col}</td>)
                            }

                        </tr>
                    )
                }
            </tbody>

        </Table>

    );

}



export default DataTable;