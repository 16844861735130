import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import TrainModel from "./views/TrainModel";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";
import "./MiaTheme.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import ModelList from "./views/ModelList";
import BatchPredictionWizard from "./views/wizards/BatchPredictionWizard/BatchPredictionWizard";
import LimitedBeta from "./views/LimitedBeta";
import PredictionJobList from "./views/PredictionJobList";
import ModelTrainingInfo, { ModelDetail } from "./views/ModelDetail";
initFontAwesome();

const App = () => {
  const { isLoading, error } = useAuth0();


  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-5">

          {error && <LimitedBeta />}
          {!error && <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/profile" component={Profile} />
            <Route path="/model" component={ModelList} exact={true} />
            <Route path="/model/train" component={TrainModel} exact={true} />
            <Route path="/model/predict/:modelId/:modelVersionId/batch" component={BatchPredictionWizard} />
            <Route path="/model/:modelId/:modelVersionId/detail" component={ModelDetail} />
            <Route path="/predictions" component={PredictionJobList} />
          </Switch>
          }
        </Container>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
