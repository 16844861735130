import React, { useEffect, useState } from 'react';

import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';

const TargetDataSelector = (props) => {

    const { targetVariable, setTargetVariable, featureList } = props;

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (

        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle color="primary" caret>{targetVariable ? `Target Variable: ${targetVariable}` : "Select Target Variable"}</DropdownToggle>
            <DropdownMenu onSelect={(e) => setTargetVariable(e.target.value)}>
                {featureList.map((h) => <DropdownItem onClick={() => setTargetVariable(h)}>{h}</DropdownItem>)}
            </DropdownMenu>
        </Dropdown>

    );

};

export default TargetDataSelector;