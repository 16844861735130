import React, { Fragment } from "react";


import { Container } from "reactstrap";

const LimitedBeta = () => (
    <Container>

        <h1>Thank you for your interest!</h1>

        <p>Mia's new system is in limited pilot right now. We hope to open it up to general sign-ups in the near future!</p>
        <p>If you are a pilot user, and believe you are getting this page in error, please contact your Mia representative.</p>

    </Container>
);

export default LimitedBeta;
