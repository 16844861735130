import React, { Fragment } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Container, Row, Col } from 'reactstrap';

import { useWizard } from 'react-use-wizard';
import { Button, Form, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';

const ModelIdentification = ({ modelIdentification, setModelIdentification }) => {
    const { handleStep } = useWizard();

    // Attach an optional handler
    handleStep(() => {
        //alert("Done getting data. About to view.");

    });

    const updateModelIdentification = (newModelIdentification) => {
        setModelIdentification({
            ...newModelIdentification,
        });
    };

    const updateModelName = (name) => {
        updateModelIdentification({
            ...modelIdentification,
            model_name: name
        })
    }

    const updateModelNotes = (notes) => {
        updateModelIdentification({
            ...modelIdentification,
            notes: notes
        })
    }

    return (

        <Form style={{ "marginTop": "4em", "marginBottom": "4em" }} >
            <FormGroup>
                <Label for="modelName">
                    Model Name
                </Label>
                <Input id="modelName" name="modelName" type="text" onChange={(e) => updateModelName(e.target.value)} />
                <FormFeedback>
                    Please enter a name for your model
                </FormFeedback>
                <FormText>
                    Please enter a friendly name you'll use to recognize this model.
                </FormText>
            </FormGroup>

            <FormGroup>
                <Label for="modelNotes">
                    Notes
                </Label>
                <Input id="modelNotes" type="textarea" name="modelNotes" onChange={(e) => updateModelNotes(e.target.value)} />
                <FormText>
                    Free-form notes you'd like to save with this model
                </FormText>
            </FormGroup>
        </Form>

    );
};

export default ModelIdentification;
