import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';

import { Container, Row, Col, Button } from 'reactstrap';
import GetDataSource from '../../../components/inference/GetDataSource';
import InferenceSubmitted from '../../../components/inference/InferenceSubmitted';
import ReviewSettings from '../../../components/inference/ReviewSettings';

import WizardPanel from '../WizardPanel';


const BatchPredictionWizard = (props) => {

    const { modelId, modelVersionId } = useParams();

    const [modelIdentification, setModelIdentification] = useState({
        model_id: modelId,
        model_version_id: modelVersionId
    });

    const [inferenceData, setInferenceData] = useState({
        acquisition: {},
        format: "Csv",
    });

    return (
        <Container fluid>

            <Row>
                <Col>
                    <Wizard>
                        <WizardPanel title="Specify Data Source"><GetDataSource inferenceData={inferenceData} setInferenceData={(data) => setInferenceData(data)} /></WizardPanel>
                        <WizardPanel title="Confirm Settings and Submit"><ReviewSettings modelIdentification={modelIdentification} inferenceData={inferenceData} /></WizardPanel>
                        <WizardPanel title="Inference Underway"><InferenceSubmitted modelIdentification={modelIdentification} inferenceData={inferenceData} /></WizardPanel>
                    </Wizard>
                </Col>
            </Row>

        </Container>

    )
}

export default BatchPredictionWizard;
